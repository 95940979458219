import React from "react";
import "./styles.css";
import useWindowSize from "hooks/use_window_size";

const Header = ({ partner, pbfs }) => {
  const size = useWindowSize();

  const callFinstead = () => {
    window.location.href = "tel:1300380041";
  };

  return (
    <>
      {size?.width < 990 && (
        <div className="fs-mobile-phone-mobile" onClick={callFinstead}>
          <i className="fa fa-phone" />
        </div>
      )}
      <header>
        <div
          id="header-one"
          className="header-height-wrap header-area header-fixed header-layout1"
        >
          <div className="mobile-phone-button">
            <i className="fa fa-phone-circle" />
          </div>
          <div className="header-top-bar">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 d-flex align-items-center">
                  <div className="header-contact">
                    <ul>
                      {/* <li><span><i className="fas fa-map-marker-alt" /></span>Finstead Capital</li> */}
                      {/* <li><span><i className="far fa-clock" /></span>Mon - Fri 8.00 - 18.00. Sunday CLOSED</li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="header-social text-right">
                    <ul>
                      <li style={{ borderRight: "1px solid #e6e6e6" }}>
                        <a
                          href="https://partners.finstead.com.au"
                          style={{ marginRight: "5px" }}
                        >
                          <i className="fa fa-handshake" /> Partner Login
                        </a>
                      </li>
                      {/* <li>
                        <a href="https://facebook.com/finsteadcapital">
                            <i className="fab fa-facebook-f" />
                        </a>
                        </li> */}
                      {/* <li>
                        <a href="#">
                            <i className="fab fa-twitter" />
                        </a>
                        </li> */}
                      <li>
                        <a href="https://www.linkedin.com/company/finstead-capital">
                          <i className="fab fa-linkedin-in" />
                        </a>
                      </li>
                      <li>
                        <a href="https://instagram.com/finsteadcapital">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-menu-wrap" id="sticker">
            <div className="container">
              <div className="row no-gutters d-flex align-items-center">
                {partner ? (
                  <div className="col-4">
                    <div className="logo-area">
                      <a
                        href="/"
                        className="logo-dark"
                        style={{
                          borderRight: "2px solid #e2e2e2",
                          marginRight: "15px",
                        }}
                      >
                        <img
                          src={partner.logo_url_dark}
                          alt="logo"
                          className="img-fluid"
                          style={{
                            marginRight: "15px",
                            maxWidth: "90px",
                            ...partner.logo_url_light_config,
                          }}
                        />
                      </a>
                      <a href="/" className="logo-dark">
                        <img
                          src="https://cdn.finstead.com.au/media/finstead.png"
                          alt="logo"
                          className="img-fluid"
                          style={{ maxWidth: "180px", width: "180px" }}
                        />
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-2 col-md-2">
                    <div className="logo-area">
                      {/* <Link to="/"> */}
                      <a href="/" className="logo-dark">
                        <img
                          src="https://cdn.finstead.com.au/media/finstead.png"
                          alt="logo"
                          className="img-fluid"
                          style={{ maxWidth: "250px" }}
                        />
                      </a>
                      {/* </Link> */}
                    </div>
                  </div>
                )}
                <div
                  className={
                    partner
                      ? "col-lg-5 col-md-5 possition-static"
                      : "col-lg-7 col-md-7 possition-static"
                  }
                >
                  <div className="template-main-menu">
                    <nav id="dropdown">
                      <ul>
                        <li>
                          <a href="/about">About</a>
                        </li>
                        <li>
                          <a href="/services">Services</a>
                          <ul className="template-dropdown-menu">
                            <li>
                              <a href="/services/asset-finance">
                                Asset Finance
                              </a>
                            </li>
                            <li>
                              <a href="/services/automotive-finance/">
                                Automotive Finance
                              </a>
                            </li>
                            <li>
                              <a href="/services/business-loans/">
                                Business Loans
                              </a>
                            </li>
                            <li>
                              <a
                                href="/services/commercial-property/"
                                style={{
                                  borderBottomLeftRadius: "10px",
                                  borderBottomRightRadius: "10px",
                                }}
                              >
                                Commercial Property
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          {/* <a href="contact.html">Contact</a> */}
                          <a href="/contact">Contact</a>
                        </li>

                        {size?.width < 990 && (
                          <li>
                            {/* <a href="contact.html">Contact</a> */}
                            <a href="https://partners.finstead.com.au">
                              Partner Login
                            </a>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="header-action-items text-right">
                    <ul>
                      <li>
                        <a
                          href="tel:+611300380041"
                          className="
                                    custom-size-md btn-fill 
                                    bg-accent border-color-accent radius-45 
                                    color-light hover-color-accent btn-icon size-xs icon-left
                                    custom-call-button
                                "
                        >
                          <i className="fas fa-phone" />
                          <span className="call-number-text">1300 380 041</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
